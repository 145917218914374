import * as React from 'react';

const CardReview = ({ title, name, children }) => {
    return (
        <div className="card-review">
            <div className="card-review__file">Благодарственное письмо</div>
            <h2 className="h2 card-review__title">{title}</h2>
            <div className="card-review__text">{children}</div>
            <div className="card-review__name">{name}</div>
        </div>
    );
};

export { CardReview };
