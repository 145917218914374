import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { Layout } from '../components/Layout';
import { Title } from '../components/Title';
import { Form } from '../components/Form';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { CardReview } from '../components/CardReview';
import { Seo } from '../components/Seo';

function IndexSite({ location }) {
    return (
        <Layout location={location}>
            <Breadcrumbs
                crumbs={[
                    ['/', 'Главная'],
                    [null, 'Отзывы'],
                ]}
            />
            <div className="container section-margin">
                <Title className="h1" level="h1">
                    Отзывы
                </Title>
                <Swiper
                    modules={[Navigation, Pagination]}
                    pagination={{
                        el: '.swiper-pagination',
                        type: 'bullets',
                    }}
                    navigation={{
                        prevEl: '.swiper-button-prev',
                        nextEl: '.swiper-button-next',
                    }}
                    autoHeight={true}
                    spaceBetween={24}
                    slidesPerView={1}
                    breakpoints={{
                        768: {
                            slidesPerView: 2,
                        },
                    }}
                >
                    <SwiperSlide>
                        <CardReview
                            file="Благодарственное письмо"
                            title="Генеральный директор «AZCORE»"
                            name="С уважением, Залетаев И.И."
                        >
                            <p>
                                Хочу поблагодарить Титову Елену, от лица всего коллектива «АЙ-ТИ КОМПАНИИ АЗКОРЕ», за
                                безупречную подготовку документации, подачу заявок и оперативное сопровождение по всем
                                вопросам, связанным с закупками по 223ФЗ и 44ФЗ.
                            </p>
                            <p>
                                Так как наша компания достаточно молодая, благодаря качественной работе Елены, мы
                                получили возможность выиграть свои первые тендеры, что дало нам возможность для
                                уверенного роста!
                            </p>
                        </CardReview>
                    </SwiperSlide>
                    <SwiperSlide>
                        <CardReview
                            file="Благодарственное письмо"
                            title="Генеральный директор «ОРЕЛФАРМАЦИЯ»"
                            name="С уважением, Дубовик T.A"
                        >
                            <p>
                                Выражаем искреннюю благодарность Тендерному бюро Титовой Е.В. за длительное и
                                плодотворное сотрудничество. Мы высоко ценим установившиеся между нами партнерские
                                отношения и взаимопонимание. Хотим подчеркнуть высокий уровень профессионализма, и
                                поблагодарить за оперативность в решении вопросов.
                            </p>
                            <p>
                                Желаем Вам дальнейших успехов ' в работе, экономической стабильности, и достижения
                                поставленных целей.
                            </p>
                        </CardReview>
                    </SwiperSlide>

                    <SwiperSlide>
                        <CardReview
                            file="Благодарственное письмо"
                            title="ИП Степашин А.Л"
                            name="С уважением, Степашин А.Л"
                        >
                            <p>
                                Хочу сообщить, что доволен тем, как Вы с блеском справляетесь с поставленными
                                профессиональными задачами. Ваша работа требует большой самоотдачи, проявления
                                личностных качеств и дополнительно затраченного времени. Наблюдая за тем, как Вы
                                работаете, добиваетесь поставленной цели и развиваетесь, не могу не отметить, что Ваш
                                потенциал и профессиональные навыки ведут мое предприятие к новым горизонтам.
                            </p>
                            <p>
                                Я и мои коллеги рады, что такой ценный сотрудник, как Вы, работает с нами. Благодарим
                                Вас за ваш талант, цепкий ум и преданность нам и выбранной профессии.
                            </p>
                        </CardReview>
                    </SwiperSlide>

                    <SwiperSlide>
                        <CardReview
                            file="Благодарственное письмо"
                            title="Администрация предприятия «Новые решения»"
                            name="С уважением, А.Ю. Сергеев"
                        >
                            <p>
                                Администрация предприятия «Новые решения» выражает искреннюю благодарность Тендерному
                                бюро Титовой Елены за участие и всестороннюю поддержку в освоении направления участия в
                                государственных закупках. Благодаря профессионализму и душевному порыву Титовой Елены и
                                ее сотрудников, предприятие получило заказы и продвинулось в достижении своей цели по
                                увеличению заказов и товарооборота предприятия
                            </p>
                            <p>Надеемся на дальнейшее плодотворное сотрудничество.</p>
                        </CardReview>
                    </SwiperSlide>

                    <SwiperSlide>
                        <CardReview
                            file="Благодарственное письмо"
                            title="ОАО «Монолит»"
                            name="С уважением, С.А. Сычугов"
                        >
                            <p>
                                ОАО «Монолит» выражает благодарность Вашей компании «Тендерное бюро Титовой Елены» за
                                помощь в развитии нашего бизнеса, за проведенное обучение сотрудников по направлению
                                «государственные и корпоративные закупки»».
                            </p>
                            <p>
                                В результате обучения уровень профессионализма сотрудников значительно повысился, что
                                положительно влияет на развитие нашей организации, а также в освоении новых направлений.
                                В результате обучения наши работники:
                                <ul>
                                    <li>Повысили свою компетентность в сфере закупок;</li>
                                    <li>Сформировали навыки работы с электронными торговыми площадками;</li>
                                </ul>
                                ОАО «Монолит» выражает надежду на дальнейшее сотрудничество с Вашей компанией и
                                рекомендует всем предприятиям сотрудничать с Вами.
                            </p>
                        </CardReview>
                    </SwiperSlide>

                    <div className="swiper-wrap-navigation-pagination">
                        <div className="swiper-button-prev">
                            <svg
                                width="66"
                                height="15"
                                viewBox="0 0 66 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M0.292953 6.79289C-0.0975723 7.18342 -0.0975723 7.81658 0.292953 8.20711L6.65691 14.5711C7.04744 14.9616 7.6806 14.9616 8.07113 14.5711C8.46165 14.1805 8.46165 13.5474 8.07113 13.1569L2.41428 7.5L8.07113 1.84315C8.46165 1.45262 8.46165 0.819457 8.07113 0.428932C7.6806 0.0384078 7.04744 0.0384078 6.65691 0.428932L0.292953 6.79289ZM65.0486 6.5L1.00006 6.5V8.5L65.0486 8.5V6.5Z" />
                            </svg>
                        </div>
                        <div className="swiper-pagination" />
                        <div className="swiper-button-next">
                            <svg
                                width="66"
                                height="15"
                                viewBox="0 0 66 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M64.846 8.20712C65.2365 7.81659 65.2365 7.18343 64.846 6.7929L58.482 0.428942C58.0915 0.038418 57.4583 0.0384179 57.0678 0.428942C56.6773 0.819466 56.6773 1.45263 57.0678 1.84316L62.7246 7.50001L57.0678 13.1569C56.6773 13.5474 56.6773 14.1806 57.0678 14.5711C57.4583 14.9616 58.0915 14.9616 58.482 14.5711L64.846 8.20712ZM0.0903319 8.5L64.1389 8.50001L64.1389 6.50001L0.0903322 6.5L0.0903319 8.5Z" />
                            </svg>
                        </div>
                    </div>
                </Swiper>
            </div>

            <Form />
        </Layout>
    );
}

export default IndexSite;

export function Head() {
    return (
        <Seo
            title="Тендерное сопровождение - отзывы о компании ГЛАВТОРГИ"
            description="Тендерное сопровождение участников госзакупок - отзывы о компании ГЛАВТОРГИ"
        />
    );
}
